/* Conteneur principal */
.partners-section {
  padding: 20px 0;
  background-color: #f9f9f9; /* Vous pouvez ajuster la couleur de fond si nécessaire */
}

.partners-content {
  text-align: center;
}

.partners-logos h5 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #333;
}

/* Grille des logos */
.logo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Éléments de logo */
.logo-item {
  flex: 1 1 calc(16.6667% - 20px); /* 6 logos par ligne sur les grands écrans */
  max-width: 150px; /* Ajustez la taille maximale pour les logos */
  box-sizing: border-box;
}

.logo-item img {
  width: 100%;
  height: auto;
  max-height: 80px; /* Limite la hauteur maximale pour les logos */
  object-fit: contain; /* Conserve le ratio des logos */
  transition: transform 0.3s ease; /* Effet de transition pour le survol */
}

/* Responsive pour les mobiles */
@media (max-width: 768px) {
  .logo-item {
    flex: 1 1 calc(50% - 20px); /* 2 logos par ligne sur les écrans plus petits */
  }
}

/* Effet de survol pour les logos */
.logo-item:hover img {
  transform: scale(1.05); /* Légère augmentation de la taille au survol */
}
