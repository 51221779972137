/* ThemesRecherche.css */

.themes-recherche-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f4f4f4;
}

.content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  color: #1962e5;
  text-align: left;
}

.content {
  font-family: Arial, sans-serif;
  color: #333;
}

.introduction, .conclusion {
  margin-bottom: 20px;
  line-height: 1.6;
}

.subheading {
  font-size: 1.5rem;
  color: #1962e5;
  margin-bottom: 10px;
}

.themes-list {
  list-style: none;
  padding: 0;
}

.themes-list li {
  margin-bottom: 10px;
}

.themes-list a {
  text-decoration: none;
  color: #1962e5;
  font-weight: bold;
}

.themes-list a:hover {
  text-decoration: underline;
}
