
  @media screen and (max-width: 767px) {
	.ptag {
	  display: none;
	}
  }


  @media screen and (max-width: 767px) {
	.mobileadded {
text-align: center;	}
  }