.logoPartenaire {
  margin-bottom: 0;
  padding-top: 5%;
  margin: auto;
  width: 26rem;
  height: 19rem;
}

@media screen and (max-width: 550px) {
    .logoPartenaire {
       
        width: 17rem;
        height: 12.5rem;
      }
  }


  .logoPartenaire2{
    margin-bottom: 0;
    padding-top: 5%;
    margin: auto;
    width: 26rem;
    height: 19rem;
  }
  
  @media screen and (max-width: 550px) {
      .logoPartenaire2 {
         
        width: 26rem;
        height: 12.5rem;
        }
    }
  
  

@media screen and (max-width: 450px) {
  .imglogopartnaire {
    text-align: center;
  }
}
.imglogopartnaire {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .fifty {
    width: 100%;
    text-align: center;
  }
}

.divecole {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 55rem;
}

.divecole2 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 75rem;
}

.divecole3 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 40rem;
}

.divecole4 {
  background-color: rgb(248, 248, 248);
  margin-top: 1.3%;
  padding: 1rem;
  text-align: center;
  padding-bottom: 2rem;
  height: 55rem;
}
