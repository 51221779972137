


@media screen and (max-width: 650px) {
    .image{
        height: 20rem;
    }
}


.slick-arrow .slick-prev{
    opacity: 0;
}


@media screen and (min-width: 651px)  and (max-width: 999px) {
    .image{
        height: 40rem;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1649px) {
    .image{
        height: 80rem;
    }
}

@media screen and (min-width: 1650px) {
    .image{
        height: 95rem;
    }
}


.disabled {
    pointer-events: none; /* Désactive les événements de pointeur, rendant le lien non cliquable */
    color: #999; /* Couleur du texte grise pour indiquer qu'il est désactivé */
    cursor: default; /* Curseur par défaut pour indiquer qu'il est désactivé */
  }
  

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    position: relative;
  }
  