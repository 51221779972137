/* Style du label contenant la case à cocher */
.consent-label {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}



/* Effet quand la case est cochée */
.consent-checkbox:checked {
  background-color: #4cb6ea;
  border-color: #4cb6ea;
  box-shadow: 0 0 5px rgba(76, 182, 234, 0.6);
}

/* Ajout d'un effet de coche dans la case à cocher */
.consent-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 6px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Style du texte à côté de la case */
.consent-text {
  font-size: 0.9em;
  color: #555;
  transition: color 0.3s ease;
}

/* Changement de couleur du texte lorsque la case est survolée */
.consent-label:hover .consent-text {
  color: #4cb6ea;
}


.labeldiv{
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 7%;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4cb6ea;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
